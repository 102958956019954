exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kardec-tsx": () => import("./../../../src/pages/kardec.tsx" /* webpackChunkName: "component---src-pages-kardec-tsx" */),
  "component---src-pages-olivrodosespiritos-common-tsx": () => import("./../../../src/pages/olivrodosespiritos/common.tsx" /* webpackChunkName: "component---src-pages-olivrodosespiritos-common-tsx" */),
  "component---src-pages-olivrodosespiritos-dynamic-tsx": () => import("./../../../src/pages/olivrodosespiritos/dynamic.tsx" /* webpackChunkName: "component---src-pages-olivrodosespiritos-dynamic-tsx" */),
  "component---src-pages-preces-ave-maria-tsx": () => import("./../../../src/pages/preces/ave-maria.tsx" /* webpackChunkName: "component---src-pages-preces-ave-maria-tsx" */),
  "component---src-pages-preces-caritas-tsx": () => import("./../../../src/pages/preces/caritas.tsx" /* webpackChunkName: "component---src-pages-preces-caritas-tsx" */),
  "component---src-pages-preces-pai-nosso-tsx": () => import("./../../../src/pages/preces/pai-nosso.tsx" /* webpackChunkName: "component---src-pages-preces-pai-nosso-tsx" */),
  "component---src-templates-olivrodosespiritos-view-tsx": () => import("./../../../src/templates/olivrodosespiritos/view.tsx" /* webpackChunkName: "component---src-templates-olivrodosespiritos-view-tsx" */)
}

